import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3000';

const usersApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: false
});

// Add interceptor to handle errors
usersApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
      console.error('CORS Error:', error);
      // Handle CORS error
    }
    return Promise.reject(error);
  }
);

export default {
  getUserProfile(identifier) {
    console.log(`Identifier: ${identifier}`);
    const param = identifier.replace(/^@/, '');
    const endpoint = identifier.startsWith('@')
      ? `/user/handle/${param}`
      : `/user/${param}`;
    console.log(`Fetching profile for user: ${endpoint}`);
    return usersApi.get(endpoint).then(response => {
      console.log('API Response:', response.data);
      return response;
    });
  },
  
  // Add other user-related API calls here
  initiateEmailSignIn(email) {
    return usersApi.post('/auth/initiate-email-signin', { email });
  },

  verifyMagicLink(token) {
    console.log('Sending magic link verification request with token:', token);
    return usersApi.post('/auth/verify-magic-link', { token })
      .then(response => {
        console.log('Magic link verification response:', response.data);
        return response;
      })
      .catch(error => {
        console.error('Magic link verification API error:', error.response?.data || error);
        throw error;
      });
  },
  
  completeEmailSignIn(token) {
    return usersApi.post('/auth/complete-email-signin', { token });
  },
  
  respondToAuthChallenge(data) {
    return usersApi.post('/auth/respond-to-challenge', data);
  },
  
  // Add password sign-in method
  passwordSignIn(email, password) {
    return usersApi.post('/auth/password-signin', { email, password });
  },

  // Add after the existing endpoints
  logout() {
    return usersApi.post('/auth/logout');
  },

  refreshToken(refreshToken) {
    return axios.post(`${process.env.VUE_APP_API_URL}/auth/refresh`, 
      { refreshToken },
      { withCredentials: true }
    );
  },
};
