<script setup>
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { computed } from 'vue'

const { t, locale } = useI18n()

const metaInfo = computed(() => ({
  title: t('meta.title'),
  meta: [
    {
      name: 'description',
      content: t('meta.description')
    },
    {
      property: 'og:title',
      content: t('meta.title')
    },
    {
      property: 'og:description',
      content: t('meta.description')
    },
    {
      property: 'og:locale',
      content: locale.value
    },
    {
      property: 'og:url',
      content: 'https://apprendo.co/'
    },
    {
      property: 'og:type',
      content: 'website'
    }
  ]
}))

useHead(metaInfo)
</script>

<template>
  <div></div>
</template>