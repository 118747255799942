<template>
  <div id="app">
    <AppHeader v-if="!excludedRoutes.includes($route.name)" />
    <MetaTags />
    <router-view></router-view>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import MetaTags from '@/components/MetaTags.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    MetaTags
  },
  data() {
    return {
      excludedRoutes: [] // Empty array means header shows on all routes
    }
  }
}
</script>
