<template>
  <div class="modal-overlay" v-if="show" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <img src="@/assets/apprendo-logo.png" alt="Apprendo Logo" class="logo">
        <span class="close" @click="$emit('close')">&times;</span>
      </div>
      <h2>Sign in to Apprendo</h2>

      <!-- Initial State -->
      <template v-if="signInState === 'initial'">
        <form @submit.prevent="handleMagicLinkSignIn">
          <div class="form-group">
            <input 
              type="email" 
              v-model="email"
              placeholder="Your email"
              required
              :class="{ error: errors.email }"
            />
            <span class="error-message" v-if="errors.email">{{ errors.email }}</span>
          </div>
          <button type="submit" class="btn-primary" :disabled="loading">
            {{ loading ? 'Sending...' : 'Sign in with email' }}
          </button>
        </form>
        <div class="divider">or</div>
        <button @click="signInState = 'password'" class="btn-secondary">
          Sign in with password
        </button>
      </template>

      <!-- Email Sent State -->
      <template v-else-if="signInState === 'emailSent'">
        <p>Check your email</p>
        <p>If you have an account with us, we've sent an email to {{ email }} with a link that you can use to sign in.</p>
        <p>You can also <a @click="resetSignInState">try again</a> or <a @click="signInState = 'password'">sign in using your password</a>.</p>
      </template>

      <!-- Password State -->
      <template v-else-if="signInState === 'password'">
        <form @submit.prevent="handlePasswordSignIn">
          <div class="form-group">
            <input 
              type="email" 
              v-model="email"
              placeholder="Your email"
              required
              :class="{ error: errors.email }"
            />
          </div>
          <div class="form-group">
            <div class="password-input">
              <input 
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                placeholder="Password"
                required
                :class="{ error: errors.password }"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                title="Password must be at least 8 characters and include uppercase, lowercase, number and special character"
              />
              <button 
                type="button" 
                class="toggle-password"
                @click="showPassword = !showPassword"
              >
                {{ showPassword ? '👁️' : '👁️‍🗨️' }}
              </button>
            </div>
            <span class="error-message" v-if="errors.password">{{ errors.password }}</span>
          </div>
          <button type="submit" class="btn-primary" :disabled="loading">
            {{ loading ? 'Signing in...' : 'Sign in' }}
          </button>
        </form>
        <p><a @click="resetSignInState">Back to email sign in</a></p>
      </template>

      <div class="error-message" v-if="generalError">
        {{ generalError }}
      </div>
      
      <p class="create-account">
        First time here? <a @click="$emit('showSignUp')">Create an account</a>
      </p>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'SignInModal',
  props: {
    show: Boolean
  },
  emits: ['close', 'showSignUp'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const loading = ref(false);
    const showPassword = ref(false);
    const signInState = ref('initial'); // 'initial', 'emailSent', 'password'
    const errors = reactive({
      email: '',
      password: ''
    });
    const generalError = ref('');

    const validatePassword = (password) => {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return regex.test(password);
    };

    const resetSignInState = () => {
      signInState.value = 'initial';
      email.value = '';
      password.value = '';
      errors.email = '';
      errors.password = '';
      generalError.value = '';
    };

    const handleMagicLinkSignIn = async () => {
      try {
        loading.value = true;
        errors.email = '';
        generalError.value = '';

        await store.dispatch('auth/initiateEmailSignIn', email.value);
        signInState.value = 'emailSent';
      } catch (error) {
        generalError.value = error.message;
      } finally {
        loading.value = false;
      }
    };

    const handlePasswordSignIn = async () => {
      try {
        loading.value = true;
        errors.email = '';
        errors.password = '';
        generalError.value = '';

        if (!validatePassword(password.value)) {
          errors.password = 'Password must meet security requirements';
          return;
        }

        await store.dispatch('auth/passwordSignIn', {
          email: email.value,
          password: password.value
        });
        emit('close');
        router.push('/dashboard');
      } catch (error) {
        if (error.code === 'NotAuthorizedException') {
          generalError.value = 'Invalid email or password';
          password.value = '';
        } else {
          generalError.value = error.message;
        }
      } finally {
        loading.value = false;
      }
    };

    return {
      email,
      password,
      loading,
      showPassword,
      signInState,
      errors,
      generalError,
      handleMagicLinkSignIn,
      handlePasswordSignIn,
      resetSignInState
    };
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #2c2c2c;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.logo {
  height: 40px;
}

.close {
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
}

h2 {
  color: white;
  margin-bottom: 1.5rem;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: white;
  width: 100%;
  box-sizing: border-box;
}

.btn-primary, .btn-secondary {
  padding: 0.75rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

.btn-primary {
  background-color: #ff4500;
  color: white;
}

.btn-secondary {
  background-color: transparent;
  color: #ff4500;
  border: 1px solid #ff4500;
  margin-top: 1rem;
}

.divider {
  text-align: center;
  margin: 1rem 0;
  color: #999;
}

.create-account {
  text-align: center;
  margin-top: 1.5rem;
  color: #999;
}

.create-account a {
  color: #ff4500;
  cursor: pointer;
  text-decoration: underline;
}

a {
  color: #ff4500;
  cursor: pointer;
  text-decoration: underline;
}

.error-message {
  color: #ff4500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

.password-input {
  position: relative;
  width: 100%;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.error {
  border-color: #ff4500;
}

.error-message {
  color: #ff4500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
</style>
