import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    header: {
      signIn: 'Sign in',
      startTalk: 'Start Your Apprendo Talk',
      startTheTalk: 'Start The Apprendo Talk'
    },
    hero: {
      tagline: 'For Busy Professionals & Experts',
      subtitle: 'The platform for guided expert conversations',
      title: {
        line1: 'Grow your <span class="underline">professional</span> <span class="text-[#EB3B38]">brand.</span>',
        line2: 'Share your <span class="text-[#EB3B38]">expertise.</span>',
        line3: 'Monetize your <span class="text-[#EB3B38]">services.</span>'
      },
      experts: {
        Eduardo_Ramirez: {
          name: 'Eduardo Ramirez',
          description: 'Eduardo Ramirez is a software engineer and entrepreneur.',
          title: 'Director of Artificial Intelligence',
          videoTag: '30-minute knowledge sharing',
          position: 'Director of Artificial Intelligence, Ensitech',
          testimonial: '"I\'ve tripled my consultation requests and landed a dream contract."',
          change: 'From limited visibility to recognized industry expert through consistent Apprendo Talks',
          metric: 'Visibility Growth',
        },
        Hai_Phung_Hiem: {
          name: 'Hai Phung Hiem',
          description: 'Hai Phung Hiem is a software engineer and entrepreneur.',
          title: 'Cats with Bats',
          videoTag: 'Building community through expertise',
          position: 'Director of Artificial Intelligence, Ensitech',
          testimonial: '"I\'ve tripled my consultation requests and landed a dream contract."',
          change: 'From limited visibility to recognized industry expert through consistent Apprendo Talks',
          metric: 'Visibility Growth'
        },
        Hassaan_Khan: {
          name: 'Hassaan Khan',
          description: 'Hassaan Khan is a software engineer and entrepreneur.',
          title: 'Professional Growth',
          videoTag: 'Scaling expertise globally',
          position: 'Director of Artificial Intelligence, Ensitech',
          testimonial: '"I\'ve tripled my consultation requests and landed a dream contract."',
          change: 'From limited visibility to recognized industry expert through consistent Apprendo Talks',
          metric: 'Visibility Growth'
        },
        IMEF: {
            name: 'IMEF',
            description: 'Hassaan Khan is a software engineer and entrepreneur.',
            title: 'Professional Growth',
            videoTag: 'Scaling expertise globally',
            position: 'Professional Association',
            testimonial: '"Highlighting our experts through Apprendo has revolutionized the value we deliver to our members. Our community of professionals are now recognized as thought leaders and are growing their practices, making our association essential to the professionals we serve."',
            change: 'From traditional association to impactful, go-to expert network',
            metric: 'Membership Growth'
        },
        Efrain_Zapata: {
          name: 'Efrain Zapata',
          description: 'Efrain Zapata is a software engineer and entrepreneur.',
          title: 'Professional Growth',
          videoTag: 'Scaling expertise globally',
          position: 'Professional Association',
          testimonial: '"Apprendo has transformed my leadership insights into a thriving community, turning my client base into subscribers."',
          change: 'From occasional workshops to a scalable knowledge-sharing platform',
          metric: 'Monthly Revenue'
        }

      },
    },
    why: {
      title: 'Expertise delivered in many forms.',
      subtitle: "What's yours?"
    },
    what: {
      title: 'The complete platform for knowledge monetization,',
      subtitle: 'powered by:',
        features:{
            talk:{
                title:"The Apprendo Talk",
                description:"Our signature interview process that extracts and transforms expertise through natural conversation"
            },
            distribution:{
                title:"Automatic Distribution",
                description:"Direct LinkedIn posting and smart content distribution to your audience"
            },
            revenue:{
                title:"Revenue Engine",
                description:"Turn followers into subscribers with custom monetization portals"
            }
        },
        experts:{
            title:"For Experts",
            features:{
                talk:{
                    title:"Weekly guided expert interviews",
                    description:"30-minute conversations that create weeks of content"
                },
                distribution:{
                    title:"Automated content creation & distribution",
                    description:"Direct LinkedIn posting and subscriber sharing"
                },
                revenue:{
                    title:"Audience monetization",
                    description:"Convert followers to paying subscribers"
                },
                analytics:{
                    title:"Performance analytics",
                    description:"Track engagement, growth, and revenue"
                }
            }
        },
        organizations:{
            title:"For Organizations",
            features:{
                branded:{
                    title:"Custom branded portals",
                    description:"Your brand, your experience, our technology "
                },
                billing:{
                    title:"Professional billing & payments",
                    description:"Enterprise-grade billing and payment processing"
                },
                team:{
                    title:"Team management",
                    description:"Multi-expert coordination and oversight"
                },
                integrations:{
                    title:"Enterprise integrations",
                    description:"Connect with your existing systems"
                }
            }
        },
        stats:{
            talk:{  
                title:"Weekly Talk",
                subtitle:"Our platform is used by thousands of experts and organizations to monetize their expertise"
            },
            automated:{
                title:"Automated",
                subtitle:"Our platform is used by thousands of experts and organizations to monetize their expertise"
            },
            distribution:{
                title:"Distribution",
                subtitle:"Our platform is used by thousands of experts and organizations to monetize their expertise"
            },
            linkedin:{
                title:"LinkedIn Sharing",
                subtitle:"Our platform is used by thousands of experts and organizations to monetize their expertise"
            }
        }
    },
    how: {
        title:" Share your expertise through natural conversation,",
        subtitle:"we handle everything else.",
        features:{
            talk:{
                title:"Done With Just a Talk",
                description:"No writing required. One 30-minute conversation creates weeks of premium content."
            },
            automation:{
                title:"Complete Automation",
                description:"We create, promote, deliver, and monetize everything. You just show up and talk."
            },
            power:{
                title:"Enterprise-Grade Power",
                description:"Custom portals, professional billing, and personalized delivery for your key clients."
            }
        }
    },
    cta: {
        title:"Ready to Scale Your Expertise?",
        subtitle:"Join experts who are growing their revenue with just 30 minutes per week.",
        button:"Start Your Apprendo Talk",
        link:"https://calendly.com/shane-maley/30min"
    },
    meta: {
      title: 'Apprendo | Grow Your Brand & Monetize Your Expertise',
      description: 'Transform your expertise into revenue through guided conversations. Share your knowledge and grow your professional brand.'
    }
  },
  es: {
    header: {
      startTalk: 'Inicia tu Apprendo Talk',
      startTheTalk: 'Inicia el Apprendo Talk'
    },
    hero: {
      tagline: 'Para Profesionales y Expertos',
      subtitle: 'La plataforma para conversaciones guiadas con expertos',
      title: {
        line1: 'Crece tu <span class="text-[#EB3B38]">marca</span> <span class="underline">profesional.</span>',
        line2: 'Comparte tu <span class="text-[#EB3B38]">experiencia.</span>',
        line3: 'Monetiza tus <span class="text-[#EB3B38]">servicios.</span>'
      },
      experts: {
        Eduardo_Ramirez: {
          name: 'Eduardo Ramirez',
          description: 'Eduardo Ramirez es ingeniero de software y emprendedor.',
          title: 'Director de Inteligencia Artificial',
          videoTag: 'Compartiendo conocimiento en 30 minutos',
          position: 'Director de Inteligencia Artificial, Ensitech',
          testimonial: '"He triplicado mis solicitudes de consultoría y conseguí un contrato soñado."',
          change: 'De visibilidad limitada a experto reconocido en la industria a través de Apprendo Talks',
          metric: 'Crecimiento de Visibilidad'
        },
        Hai_Phung_Hiem: {
          name: 'Hai Phung Hiem',
          title: 'Cats with Bats',
          videoTag: 'Construyendo comunidad a través de la experiencia',
          position: 'Director de Inteligencia Artificial, Ensitech',
          testimonial: '"He triplicado mis solicitudes de consultoría y conseguí un excelente contrato."',
          change: 'De visibilidad limitada a experto reconocido en la industria',
          metric: 'Crecimiento de Visibilidad'
        },
        Hassaan_Khan: {
          name: 'Hassaan Khan',
          title: 'Crecimiento Profesional',
          videoTag: 'Escalando experiencia globalmente',
          position: 'Director de Inteligencia Artificial, Ensitech',
          testimonial: '"He triplicado mis solicitudes de consultoría y conseguí un contrato soñado."',
          change: 'De visibilidad limitada a experto reconocido en la industria',
          metric: 'Crecimiento de Visibilidad'
        },
        IMEF: {
          name: 'IMEF',
          title: 'Crecimiento Profesional',
          videoTag: 'Escalando experiencia globalmente',
          position: 'Asociación Profesional',
          testimonial: '"Destacar a nuestros expertos a través de Apprendo ha revolucionado el valor que entregamos a nuestros miembros. Nuestra comunidad de profesionales ahora son reconocidos como líderes de opinión y están haciendo crecer sus prácticas, haciendo que nuestra asociación sea esencial para los profesionales a los que servimos."',
          change: 'De asociación tradicional a red de expertos influyente',
          metric: 'Crecimiento de Membresía'
        },
        Efrain_Zapata: {
          name: 'Efrain Zapata',
          title: 'Crecimiento Profesional',
          videoTag: 'Escalando experiencia globalmente',
          position: 'Asociación Profesional',
          testimonial: '"Apprendo ha transformado mis conocimientos de liderazgo en una comunidad próspera, convirtiendo mi base de clientes en suscriptores."',
          change: 'De talleres ocasionales a una plataforma escalable de intercambio de conocimientos',
          metric: 'Ingresos Mensuales'
        }
      }
    },
    why: {
      title: 'Experiencia entregada en múltiples formas.',
      subtitle: "¿Cuál es la tuya?"
    },
    what: {
      title: 'La plataforma completa para monetizar el conocimiento,',
      subtitle: 'impulsada por:',
      features: {
        talk: {
          title: "Apprendo Talks",
          description: "Nuestro proceso de entrevista que extrae y transforma la experiencia a través de una conversación natural"
        },
        distribution: {
          title: "Distribución Automática",
          description: "Publicación directa en LinkedIn y distribución inteligente de contenido a tu audiencia"
        },
        revenue: {
          title: "Motor de Ingresos",
          description: "Convierte seguidores en suscriptores con portales de monetización personalizados"
        }
      },
      experts: {
        title: "Para Expertos",
        features: {
          talk: {
            title: "Entrevistas semanales guiadas",
            description: "Conversaciones de 30 minutos que crean semanas de contenido"
          },
          distribution: {
            title: "Creación y distribución automatizada",
            description: "Publicación directa en LinkedIn y compartir con suscriptores"
          },
          revenue: {
            title: "Monetización de audiencia",
            description: "Convierte seguidores en suscriptores de pago"
          },
          analytics: {
            title: "Análisis de rendimiento",
            description: "Seguimiento de engagement, crecimiento e ingresos"
          }
        }
      },
      organizations: {
        title: "Para Organizaciones",
        features: {
          branded: {
            title: "Portales personalizados con marca",
            description: "Tu marca, tu experiencia, nuestra tecnología"
          },
          billing: {
            title: "Facturación y pagos profesionales",
            description: "Procesamiento de facturación y pagos de nivel empresarial"
          },
          team: {
            title: "Gestión de equipos",
            description: "Coordinación y supervisión multi-experto"
          },
          integrations: {
            title: "Integraciones empresariales",
            description: "Conecta con tus sistemas existentes"
          }
        }
      },
      stats:{
        talk:{  
            title:"Platica Semanal",
            subtitle:"Our platform is used by thousands of experts and organizations to monetize their expertise"
        },
        automated:{
            title:"Automatizado",
            subtitle:"Our platform is used by thousands of experts and organizations to monetize their expertise"
        },
        distribution:{
            title:"Posicionamiento en LinkedIn",
            subtitle:"Our platform is used by thousands of experts and organizations to monetize their expertise"
        },
        linkedin:{
            title:"LinkedIn Sharing",
            subtitle:"Our platform is used by thousands of experts and organizations to monetize their expertise"
        }
    }
    },
    how: {
      title: "Comparte tu experiencia a través de una conversación natural,",
      subtitle: "nosotros nos encargamos del resto.",
      features: {
        talk: {
          title: "Hecho Con Solo Una Charla",
          description: "Sin necesidad de escribir. Una conversación de 30 minutos crea semanas de contenido premium."
        },
        automation: {
          title: "Automatización Completa",
          description: "Nosotros creamos, promovemos, entregamos y monetizamos todo. Tú solo preséntate y habla."
        },
        power: {
          title: "Poder de Nivel Empresarial",
          description: "Portales personalizados, facturación profesional y entrega personalizada para tus clientes clave."
        }
      }
    },
    cta: {
      title: "¿Listo para Escalar tu Experiencia?",
      subtitle: "Únete a expertos que están aumentando sus ingresos con solo 30 minutos por semana.",
      button: "Inicia tu Apprendo Talk",
      link: "https://calendly.com/camilorivera-1/30min"
    },
    meta: {
      title: 'Apprendo | Crece tu marca personal & Monetiza tu experiencia',
      description: 'Transforma tu experiencia en ingresos a través de conversaciones guiadas. Comparte tu conocimiento y haz crecer tu marca profesional.'
    }
  },
  fr: {
    header: {
        startTalk: 'Commencez votre Apprendo Talk',
        startTheTalk: 'Commencez l\'Apprendo Talk'
      },
    hero: {
      tagline: 'Pour les Professionnels et Experts Occupés',
      subtitle: 'La plateforme pour des conversations guidées avec des experts',
      title: {
        line1: 'Développez votre <span class="underline">marque</span> <span class="text-[#EB3B38]">professionnelle.</span>',
        line2: 'Partagez votre <span class="text-[#EB3B38]">expertise.</span>',
        line3: 'Monétisez vos <span class="text-[#EB3B38]">services.</span>'
      },
      experts: {
        Eduardo_Ramirez: {
          name: 'Eduardo Ramirez',
          description: 'Eduardo Ramirez est ingénieur logiciel et entrepreneur.',
          title: 'Directeur de l\'Intelligence Artificielle',
          videoTag: 'Partage de connaissances en 30 minutes',
          position: 'Directeur de l\'Intelligence Artificielle, Ensitech',
          testimonial: '"J\'ai triplé mes demandes de consultation et décroché un contrat de rêve."',
          change: 'De la visibilité limitée à l\'expert reconnu grâce aux Apprendo Talks',
          metric: 'Croissance de la Visibilité'
        },
        Hai_Phung_Hiem: {
          name: 'Hai Phung Hiem',
          title: 'Cats with Bats',
          videoTag: 'Construire une communauté par l\'expertise',
          position: 'Directeur de l\'Intelligence Artificielle, Ensitech',
          testimonial: '"J\'ai triplé mes demandes de consultation et décroché un contrat de rêve."',
          change: 'De la visibilité limitée à l\'expert reconnu dans l\'industrie',
          metric: 'Croissance de la Visibilité'
        },
        IMEF: {
          name: 'IMEF',
          title: 'Croissance Professionnelle',
          videoTag: 'Expertise à l\'échelle mondiale',
          position: 'Association Professionnelle',
          testimonial: '"Mettre en valeur nos experts via Apprendo a révolutionné la valeur que nous apportons à nos membres. Notre communauté de professionnels est maintenant reconnue comme leaders d\'opinion et développe ses pratiques, rendant notre association essentielle pour les professionnels que nous servons."',
          change: 'D\'une association traditionnelle à un réseau d\'experts influent',
          metric: 'Croissance des Adhésions'
        },
        Efrain_Zapata: {
          name: 'Efrain Zapata',
          title: 'Croissance Professionnelle',
          videoTag: 'Expertise à l\'échelle mondiale',
          position: 'Association Professionnelle',
          testimonial: '"Apprendo a transformé mes connaissances en leadership en une communauté florissante, convertissant ma clientèle en abonnés."',
          change: 'Des ateliers occasionnels à une plateforme évolutive de partage des connaissances',
          metric: 'Revenu Mensuel'
        }
      }
    },
    why: {
      title: 'L\'expertise livrée sous de nombreuses formes.',
      subtitle: "Quelle est la vôtre?"
    },
    what: {
      title: 'La plateforme complète pour monétiser les connaissances,',
      subtitle: 'propulsée par:',
      features: {
        talk: {
          title: "L'Entretien Apprendo",
          description: "Notre processus d'entretien qui extrait et transforme l'expertise à travers une conversation naturelle"
        },
        distribution: {
          title: "Distribution Automatique",
          description: "Publication directe sur LinkedIn et distribution intelligente de contenu à votre audience"
        },
        revenue: {
          title: "Moteur de Revenus",
          description: "Transformez les suiveurs en abonnés avec des portails de monétisation personnalisés"
        }
      },
      experts: {
        title: "Pour les Experts",
        features: {
          talk: {
            title: "Entretiens hebdomadaires guidés",
            description: "Des conversations de 30 minutes qui créent des semaines de contenu"
          },
          distribution: {
            title: "Création et distribution automatisées",
            description: "Publication directe sur LinkedIn et partage avec les abonnés"
          },
          revenue: {
            title: "Monétisation d'audience",
            description: "Convertissez les suiveurs en abonnés payants"
          },
          analytics: {
            title: "Analyse de performance",
            description: "Suivi de l'engagement, de la croissance et des revenus"
          }
        }
      },
      organizations: {
        title: "Pour les Organisations",
        features: {
          branded: {
            title: "Portails personnalisés à votre marque",
            description: "Votre marque, votre expérience, notre technologie"
          },
          billing: {
            title: "Facturation et paiements professionnels",
            description: "Traitement des factures et paiements de niveau entreprise"
          },
          team: {
            title: "Gestion d'équipe",
            description: "Coordination et supervision multi-experts"
          },
          integrations: {
            title: "Intégrations entreprise",
            description: "Connexion avec vos systèmes existants"
          }
        }
      }
    },
    how: {
      title: "Partagez votre expertise à travers une conversation naturelle,",
      subtitle: "nous nous occupons du reste.",
      features: {
        talk: {
          title: "Réalisé Avec Un Simple Entretien",
          description: "Pas besoin d'écrire. Une conversation de 30 minutes crée des semaines de contenu premium."
        },
        automation: {
          title: "Automatisation Complète",
          description: "Nous créons, promouvons, livrons et monétisons tout. Vous n'avez qu'à vous présenter et parler."
        },
        power: {
          title: "Puissance Niveau Entreprise",
          description: "Portails personnalisés, facturation professionnelle et livraison personnalisée pour vos clients clés."
        }
      }
    },
    cta: {
      title: "Prêt à Développer Votre Expertise?",
      subtitle: "Rejoignez les experts qui augmentent leurs revenus avec seulement 30 minutes par semaine.",
      button: "Commencez Votre Entretien Apprendo",
      link: "https://calendly.com/shane-maley/30min"
    },
    meta: {
      title: 'Apprendo | Développez Votre Brand & Monétisez Votre Expertise',
      description: 'Transformez votre expertise en revenus grâce à des conversations guidées. Partagez vos connaissances et développez votre marque professionnelle.'
    }
  }
}

// Browser language detection
function getDefaultLanguage() {
  const browserLang = navigator.language.split('-')[0]
  return ['en', 'es', 'fr'].includes(browserLang) ? browserLang : 'en'
}

export const i18n = createI18n({
  legacy: false,
  locale: getDefaultLanguage(),
  fallbackLocale: 'en',
  messages
})