import api from './index'
import store from '../store'
import axios from 'axios'

export default {
  initializeVideo(id_corporation) {
    return api.post('/videos/initialize', { id_corporation });
  },

  getUploadUrl({ id_video, id_corporation, id_course_module, filename, contentType, fileSize }) {
    return api.post('/videos/upload-url', { 
      id_video, 
      id_corporation,
      id_course_module,
      filename, 
      contentType,
      fileSize 
    });
  },

  uploadToS3(file, uploadUrl, onProgress) {
    const s3Client = axios.create();
    
    return s3Client.put(uploadUrl, file, {
      withCredentials: false,
      headers: { 
        'Content-Type': file.type,
        'x-amz-acl': 'private',
        'x-amz-meta-videoid': file.videoId,
        'x-amz-meta-corporationid': file.corporationId,
        'x-amz-meta-coursemoduleid': file.courseModuleId,
        'x-amz-meta-originalname': file.name
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        onProgress(Math.round(progress));
      },
      transformResponse: [(data) => {
        try {
          return JSON.parse(data);
        } catch (e) {
          return data;
        }
      }]
    });
  },

  startVideoProcessing(id_video, id_course_module) {
    return api.post(`/videos/${id_video}/process`, { id_course_module });
  },

  getVideoByModuleId(id_course_module) {
    const id_corporation = store.state.auth.user.id_corporation;
    return api.get(`/videos/module/${id_course_module}`, {
      headers: {
        'X-Corporation-Id': id_corporation,
        'Content-Type': 'application/json'
      }
    });
  },

  getMuxUploadUrl({ id_video, id_corporation, id_course_module }) {
    return api.post('/videos/mux-upload-url', {
      id_video,
      id_corporation,
      id_course_module
    }, {
      headers: {
        'X-Corporation-Id': id_corporation,
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  },

};