import usersApi from '@/api/users';

export default {
  namespaced: true,
  state: {
    currentProfile: null,
    viewedProfile: null,
    loading: false,
    error: null
  },
  mutations: {
    SET_CURRENT_PROFILE(state, profile) {
      state.currentProfile = profile;
    },
    SET_VIEWED_PROFILE(state, profile) {
      state.viewedProfile = profile;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    }
  },
  actions: {
    async fetchUserProfile({ commit }, { identifier, isAuthUser }) {
      console.log('[Users Store] Starting profile fetch for:', identifier);
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);
      
      try {
        const response = await usersApi.getUserProfile(identifier);
        console.log('[Users Store] Profile data received:', response.data.user);
        const userData = {
          ...response.data.user,
          id: response.data.user.id,
          id_corporation: response.data.user.id_corporation,
          name: response.data.user.name,
          profilePicture: response.data.user.profilePicture || response.data.user.profile_picture,
          coverImage: response.data.user.coverImage || response.data.user.image_cover,
          unique_handler: response.data.user.unique_handler,
          username: response.data.user.username
        };
        console.log('[Users Store] Normalized user data:', userData);
        
        // Store in appropriate state based on whether this is the auth user
        if (isAuthUser) {
          commit('SET_CURRENT_PROFILE', userData);
        } else {
          commit('SET_VIEWED_PROFILE', userData);
        }
        
        return userData;
      } catch (error) {
        commit('SET_ERROR', error.message);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};
