import axios from 'axios'
import store from '../store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  config => {
    const token = store.state.auth.tokens?.idToken;
    console.log('Request config:', {
      url: config.url,
      method: config.method,
      hasToken: !!token
    });
    
    if (!token) {
      console.warn('No auth token found in store');
      return config;
    }
    
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
)

// Update response interceptor to handle token refresh
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        const refreshToken = store.state.auth.tokens?.refreshToken;
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        // Call refresh token endpoint
        const response = await api.post('/auth/refresh', { refreshToken });
        const newIdToken = response.data.idToken;

        // Update store with new token
        await store.commit('auth/setAuthTokens', {
          ...store.state.auth.tokens,
          idToken: newIdToken
        });

        // Retry original request with new token
        originalRequest.headers.Authorization = `Bearer ${newIdToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        await store.dispatch('auth/logout');
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
)

api.interceptors.request.use(async config => {
  const state = store.state.auth;
  if (!state.tokens?.idToken) return config;

  // Check if token needs refresh (5 minutes before expiry)
  const shouldRefresh = state.tokens.expiresAt && 
    (state.tokens.expiresAt - Date.now() < 5 * 60 * 1000);

  if (shouldRefresh) {
    try {
      await store.dispatch('auth/refreshToken');
    } catch (error) {
      console.error('Token refresh failed:', error);
      // Continue with request even if refresh fails
    }
  }

  config.headers.Authorization = `Bearer ${state.tokens.idToken}`;
  return config;
});

export default api
