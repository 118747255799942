import usersApi from '@/api/users';

const loadState = () => {
  try {
    const authState = localStorage.getItem('authState');
    if (!authState) return null;
    
    const parsed = JSON.parse(authState);
    const normalizedUser = parsed.user ? {
      ...parsed.user,
      unique_handler: parsed.user.unique_handler || parsed.user.username || '',
      username: parsed.user.username || parsed.user.unique_handler || '',
      name: parsed.user.name || '',
      profilePicture: parsed.user.profilePicture || parsed.user.profile_picture || '/default-avatar.png',
      coverImage: parsed.user.coverImage || parsed.user.image_cover || '/default-cover.jpg',
      id_corporation: parsed.user.id_corporation || ''
    } : null;

    return {
      user: normalizedUser,
      isAuthenticated: !!normalizedUser,
      tokens: parsed.tokens,
      authTokens: parsed.authTokens
    };
  } catch (e) {
    console.error('Error loading auth state:', e);
    return null;
  }
};

const initialState = loadState() || {
  user: null,
  isAuthenticated: false,
  tokens: {
    idToken: null,
    refreshToken: null,
    expiresAt: null
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    user: state => state.user,
    authStatus: state => ({
      isAuthenticated: state.isAuthenticated,
      hasUser: !!state.user,
      hasTokens: !!state.tokens
    }),
    tokens: state => state.tokens
  },
  mutations: {
    setUser(state, user) {
      state.user = {
        ...user,
        unique_handler: user.unique_handler || user.username || '',
        username: user.username || user.unique_handler || '',
        id_corporation: user.id_corporation || '',
        profilePicture: user.profilePicture || user.profile_picture || '/default-avatar.png',
        coverImage: user.coverImage || user.image_cover || '/default-cover.jpg'
      };
      state.isAuthenticated = true;
      
      // Update localStorage with new user data
      const authState = JSON.parse(localStorage.getItem('authState') || '{}');
      authState.user = state.user;
      authState.isAuthenticated = true;
      localStorage.setItem('authState', JSON.stringify(authState));
    },
    logout(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.tokens = null;
      state.authTokens = null;
      localStorage.removeItem('authState');
    },
    setEmailSignInInitiated(state, value) {
      state.emailSignInInitiated = value;
    },
    setAuthTokens(state, { idToken, refreshToken, expiresIn }) {
      state.tokens = {
        idToken,
        refreshToken,
        expiresAt: Date.now() + (expiresIn * 1000)
      };
      state.isAuthenticated = true;
      
      // Persist only necessary auth state
      const authState = {
        user: state.user,
        tokens: state.tokens,
        isAuthenticated: true
      };
      localStorage.setItem('authState', JSON.stringify(authState));
    }
  },
  actions: {
    async initiateEmailSignIn({ commit }, email) {
      try {
        await usersApi.initiateEmailSignIn(email);
        commit('setEmailSignInInitiated', true);
      } catch (error) {
        console.error('Error initiating email sign-in:', error.response?.data || error);
        throw error;
      }
    },
    async verifyMagicLink({ commit, dispatch }, { token }) {
      try {
        console.log('Starting magic link verification');
        const response = await usersApi.verifyMagicLink(token);
        console.log('Verification response:', response.data);
        
        if (!response.data) {
          throw new Error('Empty response from verification');
        }
        
        if (!response.data.user) {
          throw new Error('Verification response missing user data');
        }
        
        if (!response.data.tokens) {
          throw new Error('Verification response missing tokens');
        }
        
        // Set tokens first to ensure authenticated state
        commit('setAuthTokens', response.data.tokens);
        
        // Then set basic user data
        const basicUser = {
          username: response.data.user.email,
          profilePicture: '/default-avatar.png',
          unique_handler: response.data.user.email
        };
        
        commit('setUser', basicUser);
        
        // Fetch complete profile
        try {
          const userProfile = await dispatch('users/fetchUserProfile', {
            identifier: `${basicUser.username}`,
            isAuthUser: true
          }, { root: true });
          
          if (userProfile) {
            commit('setUser', {
              ...basicUser,
              ...userProfile,
              profilePicture: userProfile.profilePicture || basicUser.profilePicture,
              username: userProfile.username || basicUser.username,
              id_corporation: userProfile.id_corporation || '',
              unique_handler: userProfile.unique_handler || basicUser.unique_handler
            });
          }
        } catch (profileError) {
          console.error('Error fetching complete profile:', profileError);
        }
        
        return true;
      } catch (error) {
        console.error('Magic link verification failed:', error);
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        // Call API to invalidate session/tokens
        if (this.state.auth.authTokens) {
          await usersApi.logout();
        }
      } catch (error) {
        console.error('Error during logout:', error);
        // Continue with local logout even if API call fails
      } finally {
        // Clear auth state
        commit('logout');
        // Clear user profile state
        commit('users/SET_CURRENT_PROFILE', null, { root: true });
        // Clear any other related states that should be reset on logout
      }
    },
    async refreshToken({ commit, state, dispatch }) {
      try {
        if (!state.tokens?.refreshToken) {
          throw new Error('No refresh token available');
        }

        const response = await usersApi.refreshToken(state.tokens.refreshToken);
        
        // Update tokens while preserving refresh token
        const newTokens = {
          ...state.tokens,
          idToken: response.data.idToken,
          expiresIn: response.data.expiresIn
        };

        // Update tokens in state and storage
        commit('setAuthTokens', newTokens);
        localStorage.setItem('authTokens', JSON.stringify(newTokens));

        return response.data.idToken;
      } catch (error) {
        console.error('Token refresh failed:', error);
        // Force logout on refresh failure
        await dispatch('logout');
        throw error;
      }
    }
  }
};