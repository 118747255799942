<template>
  <div class="sign-in">
    <h2>Sign In</h2>
    <form @submit.prevent="handleSubmit">
      <div>
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required>
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" required>
      </div>
      <button type="submit">Sign In</button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SignIn',
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    ...mapActions(['login']),
    async handleSubmit() {
      try {
        await this.login({ email: this.email, password: this.password })
        this.$router.push('/home')
      } catch (error) {
        console.error('Login failed:', error)
        // Handle login error (e.g., show error message to user)
      }
    }
  }
}
</script>
