<template>
  <header class="w-full bg-white border-b border-gray-200">
    <!-- Full width container -->
    <div class="w-full">
      <!-- Content container with padding -->
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between items-center h-16">
          <!-- Logo -->
          <div class="flex-shrink-0">
            <router-link to="/">
              <img src="@/assets/apprendoA.svg" alt="Apprendo Logo" class="h-14" />
            </router-link>
          </div>
          
          <!-- Navigation -->
          <nav class="flex items-center space-x-4">
            <!-- Logged Out - Root Path -->
            <template v-if="!isAuthenticated && isRootPath">
              <button 
                @click="showSignInModal = true" 
                class="font-asap text-[#102340] hover:text-[#EB3B38] transition-colors"
              >
                Sign in
              </button>
              <a 
                :href="$t('cta.link')"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-flex items-center px-6 py-3 rounded-lg bg-[#EB3B38] text-white font-asap font-medium text-[16px] hover:bg-[#d43532] transition-colors"
              >
                {{ $t('header.startTalk') }}<span class="text-[12px] align-super">™</span>
                <i class="ml-2 fas fa-arrow-right"></i>
              </a>
            </template>

            <!-- User Menu (when logged in) -->
            <template v-if="isAuthenticated">
              <div class="relative" ref="userMenuRef">
                <img 
                  :src="user?.profilePicture || authUser?.profilePicture" 
                  alt="Profile" 
                  class="w-10 h-10 rounded-full cursor-pointer ring-2 ring-[#D8CAE6] hover:ring-[#EB3B38] transition-colors" 
                  @click="toggleUserMenu" 
                />
                <div 
                  v-if="showUserMenu" 
                  class="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 border border-gray-200"
                >
                  <router-link 
                    :to="`/@${userIdentifier}`"
                    class="block px-4 py-2 text-[#102340] hover:bg-[#F3F4F5] font-asap transition-colors"
                  >
                    Profile
                  </router-link>
                  <router-link 
                    to="/settings"
                    class="block px-4 py-2 text-[#102340] hover:bg-[#F3F4F5] font-asap transition-colors"
                  >
                    Settings
                  </router-link>
                  <a 
                    href="#" 
                    @click.prevent="handleLogout"
                    class="block px-4 py-2 text-[#EB3B38] hover:bg-[#F3F4F5] font-asap transition-colors"
                  >
                    Sign out
                  </a>
                </div>
              </div>
            </template>
          </nav>
        </div>
      </div>
    </div>
  </header>

  <SignInModal 
    :show="showSignInModal" 
    @close="showSignInModal = false" 
  />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import SignInModal from '@/components/SignInModal.vue'
import { useI18n } from 'vue-i18n'

useI18n()

const store = useStore()
const router = useRouter()
const route = useRoute()

const showSignInModal = ref(false)
const showUserMenu = ref(false)
const userMenuRef = ref(null)
let profileRefreshInterval = null

// Computed properties
const authUser = computed(() => store.state.auth.user)
const user = computed(() => store.state.users.currentProfile)
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
const userIdentifier = computed(() => user.value?.unique_handler || authUser.value?.unique_handler)
const isRootPath = computed(() => route.path === '/')

// Methods
const toggleUserMenu = () => {
  showUserMenu.value = !showUserMenu.value
}

const handleClickOutside = (event) => {
  if (userMenuRef.value && !userMenuRef.value.contains(event.target)) {
    showUserMenu.value = false
  }
}

const handleLogout = async () => {
  try {
    await store.dispatch('auth/logout')
    showUserMenu.value = false
    router.push('/')
  } catch (error) {
    console.error('Logout error:', error)
  }
}

const loadUserProfile = async () => {
  if (!isAuthenticated.value || !authUser.value) return
  
  try {
    await store.dispatch('users/fetchUserProfile', {
      identifier: authUser.value.unique_handler,
      isAuthUser: true
    })
  } catch (error) {
    console.error('[AppHeader] Error loading profile:', error)
  }
}

// Lifecycle hooks
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
  if (isAuthenticated.value && authUser.value?.unique_handler) {
    loadUserProfile()
    // Setup refresh interval
    profileRefreshInterval = setInterval(loadUserProfile, 5 * 60 * 1000)
  }
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
  if (profileRefreshInterval) {
    clearInterval(profileRefreshInterval)
  }
})
</script>

<style scoped>
/* Remove all existing styles as we're using Tailwind classes */
</style>
