<template>
    <footer>
      <!-- Add footer content -->
    </footer>
  </template>

  <script>
  export default {
    name: 'HomePage'
  }
  </script>