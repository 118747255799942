<template>
  <div class="video-create">
    <!-- Loading overlay -->
    <div v-if="$store.state.videos.loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    
    <div class="video-content">
      <div class="upload-section">
        <mux-uploader
          class="upload-box"
          :class="{ 'drag-over': isDragging }"
          :upload-url="muxUploadUrl"
          :max-file-size="2 * 1024 * 1024 * 1024"
          accept="video/mp4,video/quicktime,video/x-msvideo"
          @upload-start="handleUploadStart"
          @upload-end="handleUploadEnd"
          @upload-error="handleUploadError"
          @upload-progress="handleUploadProgress"
        >
          <div class="upload-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="upload-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
          </div>
          <h2>Select video file to upload</h2>
          <p class="upload-text">Or drag and drop video file</p>
          
          <div v-if="isUploading" class="upload-progress">
            <div class="progress-bar" :style="{ width: `${uploadProgress}%` }"></div>
            <span>{{ Math.round(uploadProgress) }}%</span>
          </div>

          <p class="upload-hint">Minimize processing time</p>
          <p class="upload-support">Supported video files are MP4, MOV, AVI</p>
        </mux-uploader>
      </div>

      <div class="editor-container">
        <!-- Editor Menu Bar -->
        <div class="editor-menu-bar">
          <button @click="descriptionEditor.chain().focus().undo().run()">
            <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 24 24">
              <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/>
            </svg>
          </button>
          <!-- Rest of the menu bar buttons remain the same -->
          <button @click="descriptionEditor.chain().focus().redo().run()">
            <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 24 24">
              <path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z"/>
            </svg>
          </button>
          <div class="separator"></div>
          <button 
            @click="descriptionEditor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': descriptionEditor.isActive('bold') }"
          >B</button>
          <button 
            @click="descriptionEditor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': descriptionEditor.isActive('italic') }"
          >I</button>
          <button 
            @click="descriptionEditor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': descriptionEditor.isActive('strike') }"
          >S</button>
          <button 
            @click="descriptionEditor.chain().focus().toggleCode().run()"
            :class="{ 'is-active': descriptionEditor.isActive('code') }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 24 24">
              <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/>
            </svg>
          </button>
        </div>

        <!-- Editors -->
        <div class="editor-title">
          <editor-content :editor="titleEditor" />
        </div>
        <div class="editor-subtitle">
          <editor-content :editor="subtitleEditor" />
        </div>
        <div class="editor-description">
          <editor-content :editor="descriptionEditor" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@mux/mux-uploader'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import { mapActions } from 'vuex'
import { useToast } from 'vue-toastification';

export default {
  name: 'VideoCreate',
  components: {
    EditorContent
  },
  data() {
    return {
      titleEditor: null,
      subtitleEditor: null,
      descriptionEditor: null,
      videoDetails: null,
      id_video: null,
      id_course_module: null,
      uploadProgress: 0,
      isUploading: false,
      isDragging: false,
      muxUploadUrl: null,
    }
  },
  async created() {
    const toast = useToast();
    
    try {
      // Check if user is authenticated
      if (!this.$store.state.auth.isAuthenticated) {
        this.$router.push('/login');
        throw new Error('Authentication required');
      }

      // Get and validate corporation ID
      const id_corporation = this.$store.state.auth.user?.id_corporation;
      if (!id_corporation) {
        throw new Error('Corporation ID not found');
      }

      // Get and validate module ID from route
      const id_course_module = this.$route.params.id_course_module;
      if (!id_course_module) {
        throw new Error('Module ID is required');
      }
      
      console.log("Module ID:", id_course_module);
      
      // Initialize video with validated IDs
      const response = await this.$store.dispatch('videos/getVideoByModuleId', id_course_module);
      console.log("Video details:", response.data);
      
      this.videoDetails = response.data;
      this.id_video = response.data.id_video;
      
      // Get Mux upload URL with validated credentials
      const { uploadUrl } = await this.$store.dispatch('videos/getMuxUploadUrl', {
        id_video: this.id_video,
        id_corporation,
        id_course_module
      });
      
      this.muxUploadUrl = uploadUrl;
    } catch (error) {
      console.error("Error in setup:", error);
      toast.error(error.message || "Error initializing video upload");
      if (error.message.includes('Authentication')) {
        this.$router.push('/login');
      }
    }
  },
  beforeMount() {
    this.initializeEditors();
  },
  beforeUnmount() {
    this.destroyEditors();
  },
  methods: {
    ...mapActions('theme', ['setTheme']),
    ...mapActions('videos', ['createVideoInterview']),
    
    initializeEditors() {
      this.titleEditor = new Editor({
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: 'Title'
          })
        ],
        editorProps: {
          attributes: {
            class: 'title-editor'
          }
        }
      });

      this.subtitleEditor = new Editor({
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: 'Subtitle'
          })
        ],
        editorProps: {
          attributes: {
            class: 'subtitle-editor'
          }
        }
      });

      this.descriptionEditor = new Editor({
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: 'Description'
          })
        ],
        editorProps: {
          attributes: {
            class: 'description-editor'
          }
        }
      });
    },

    destroyEditors() {
      if (this.titleEditor) this.titleEditor.destroy();
      if (this.subtitleEditor) this.subtitleEditor.destroy();
      if (this.descriptionEditor) this.descriptionEditor.destroy();
    },

    handleUploadStart(event) {
      this.isUploading = true;
      this.uploadProgress = 0;
      console.log('Upload started:', event.detail);
    },

    handleUploadProgress(event) {
      this.uploadProgress = Math.round(event.detail.progress * 100);
    },

    async handleUploadEnd(event) {
      const { asset } = event.detail;
      try {
        await this.$store.dispatch('videos/updateVideoAfterUpload', {
          id_video: this.id_video,
          uploadData: {
            muxAssetId: asset.id,
            status: 'processing',
            fileSize: event.detail.file.size,
            contentType: event.detail.file.type,
            originalName: event.detail.file.name
          }
        });

        this.$toast.success('Video uploaded successfully');
        this.isUploading = false;
      } catch (error) {
        this.$toast.error('Failed to update video status');
        console.error('Upload completion error:', error);
      }
    },

    handleUploadError(event) {
      this.isUploading = false;
      const errorMessage = event.detail?.message || 'Upload failed';
      this.$toast.error(errorMessage);
      console.error('Upload error:', event.detail);
    }
  }
}
</script>

<style scoped>
.video-create {
  --local-bg: #ffffff;
  --local-text: #2c3e50;
  
  background-color: var(--local-bg);
  color: var(--local-text);
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.video-header {
  margin-bottom: 30px;
}

.upload-box {
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  background-color: var(--bg-secondary);
  transition: all 0.3s ease;
}

.upload-box:hover {
  border-color: #666;
}

.upload-box.drag-over {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.05);
  transform: scale(1.02);
}

.upload-icon {
  margin-bottom: 1.5rem;
}

.upload-svg {
  width: 64px;
  height: 64px;
  color: #666;
  opacity: 0.8;
}

.btn-action {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: transparent;
  color: var(--text-primary);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-action:hover {
  background: var(--bg-secondary);
  border-color: #666;
}

.btn-action.primary {
  background-color: #f0f0f0;
  color: #333;
}

.btn-action.primary:hover {
  background-color: #e0e0e0;
}

.action-icon {
  width: 20px;
  height: 20px;
}

.upload-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
}

.upload-hint {
  color: #666;
  margin-top: 1.5rem;
  font-size: 0.9rem;
}

.upload-support {
  color: #999;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.upload-text {
  color: #666;
  margin: 0.5rem 0;
  font-size: 0.95rem;
}

h2 {
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.video-details {
  margin-top: 30px;
}

.title-input, .subtitle-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-bottom: 2px solid var(--border-color);
  font-size: 1.2em;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.description-input {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid var(--border-color);
  resize: vertical;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

:deep(.title-editor) {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

:deep(.title-editor p.is-editor-empty:first-child::before) {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

:deep(.subtitle-editor) {
  font-size: 1.5em;
  color: #666;
  margin-bottom: 1.5em;
}

:deep(.subtitle-editor p.is-editor-empty:first-child::before) {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

:deep(.description-editor) {
  font-size: 1.1em;
  color: var(--text-primary);
  margin-bottom: 1.5em;
}

:deep(.description-editor p.is-editor-empty:first-child::before) {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.editor-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: var(--bg-primary);
  border-radius: 8px;
}

:deep(.ProseMirror) {
  outline: none;
  min-height: 1.5em;
}

:deep(.ProseMirror p) {
  margin: 0;
}

.editor-menu-bar {
  display: flex;
  padding: 0.75rem;
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 2rem;
  gap: 0.5rem;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
}

.menu-icon {
  width: 20px;
  height: 20px;
}

.separator {
  width: 1px;
  background-color: var(--border-color);
  margin: 0 0.5rem;
}

button {
  padding: 0.5rem;
  border: none;
  background: none;
  color: var(--text-primary);
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: var(--bg-secondary);
}

button.is-active {
  background-color: var(--bg-secondary);
  color: #ff4500;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #007bff;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.upload-progress {
  margin-top: 1rem;
  background: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 20px;
}

.progress-bar {
  background: #007bff;
  height: 100%;
  transition: width 0.3s ease;
}

.drag-over {
  border-color: #666;
}
</style>